import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {Colors} from '@core/constants/colors.constants';
import {NgStyle} from '@angular/common';

@Component({
  selector: 'app-loader',
  imports: [
    NgStyle
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  sizeRem = input(4)
  isLoading = input.required();
  protected readonly IconSize = IconSize;
  protected readonly Colors = Colors;
}
