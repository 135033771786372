@if (isLoading()) {
  <div class="spinner" [ngStyle]="{'--size': sizeRem() / 4 + 'rem'}">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
}

