@if (href() !== undefined) {
  <a class="button" [ngClass]="buttonClass" [href]="href()" [target]="target()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}
@if (href() === undefined) {
  <button class="button" [ngClass]="buttonClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}
<ng-template #content>
  <app-loader [ngStyle]="{visibility: loading() ? 'visible' : 'hidden'}" [isLoading]="true" [sizeRem]="2"></app-loader>
  <div [ngStyle]="{visibility: loading() ? 'hidden' : 'visible'}">
    <ng-content></ng-content>
  </div>

</ng-template>

